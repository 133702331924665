body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #40474f;
  
  
}

code {
  
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button:hover * {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  padding: 5px 10px; /* 5px top/bottom, 10px left/right */
}

.btn, a {
  text-decoration: none;     
}